export const STORE_USER = "STORE_USER";
export const BEGIN_USER_AUTHENTIFICATION = "BEGIN_USER_AUTHENTIFICATION";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILED = "USER_LOGIN_FAILED";
export const AN_ERROR_OCCURED_DURING_LOGIN = "AN_ERROR_OCCURED_DURING_LOGIN";
export const ROLE_ACCESS_DENIED = "ROLE_ACCESS_DENIED";

export const WRONG_LOGIN_CREDENTIALS = "WRONG_LOGIN_CREDENTIALS";
export const TERMINATE_CURRENT_SESSION = "TERMINATE_CURRENT_SESSION";
export const RESET_FAILED_LOGIN = "RESET_FAILED_LOGIN";

export const START_CHECKING_IF_SYSTEM_ALREADY_CONFIGURED =
  "START_CHECKING_IF_SYSTEM_ALREADY_CONFIGURED";
export const SYSTEM_ALREADY_CONFIGURED = "SYSTEM_ALREADY_CONFIGURED";
export const SYSTEM_NOT_CONFIGURED = "SYSTEM_NOT_CONFIGURED";
export const ERROR_OCCURED_DURING_SYSTEM_CONFIG_CHECK =
  "ERROR_OCCURED_DURING_SYSTEM_CONFIG_CHECK";

export const START_INITIAL_SYSTEM_CONFIGURATION =
  "START_INITIAL_SYSTEM_CONFIGURATION";
export const INITIAL_SYSTEM_CONFIGURATION_SUCCESSFUL =
  "INITIAL_SYSTEM_CONFIGURATION_SUCCESSFUL";
export const INITIAL_SYSTEM_CONFIGURATION_FAILED =
  "INITIAL_SYSTEM_CONFIGURATION_FAILED";
export const ERROR_OCCURED_DURING_SYSTEM_CONFIGURATION =
  "ERROR_OCCURED_DURING_SYSTEM_CONFIGURATION";

export const SUCCESSFULLY_FETCHED_ALL_USERS = "SUCCESSFULLY_FETCHED_ALL_USERS";
export const FETCHING_ALL_USERS_EMPTY_RESULT_SET =
  "FETCHING_ALL_USERS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_ALL_USERS =
  "ERROR_OCCURED_WHILE_FETCHING_ALL_USERS";

export const USER_ROLE_UPDATED_SUCCESSFULLY = "USER_ROLE_UPDATED_SUCCESSFULLY";
export const USER_ROLE_UPDATE_FAILED = "USER_ROLE_UPDATE_FAILED";
export const ERROR_OCCURRED_WHILE_UPDATING_USER_ROLE =
  "ERROR_OCCURRED_WHILE_UPDATING_USER_ROLE";

export const USER_ACCESS_PRIVILEGE_UPDATED_SUCCESSFULLY =
  "USER_ACCESS_PRIVILEGE_UPDATED_SUCCESSFULLY";
export const USER_ACCESS_PRIVILEGE_UPDATE_FAILED =
  "USER_ACCESS_PRIVILEGE_UPDATE_FAILED";
export const ERROR_OCCURRED_WHILE_UPDATING_USER_ACCESS_PRIVILEGE =
  "ERROR_OCCURRED_WHILE_UPDATING_USER_ACCESS_PRIVILEGE";
