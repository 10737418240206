export const REGISTER_ACADEMIC_CLASS_LEVELS = "REGISTER_ACADEMIC_CLASS_LEVELS";
export const REGISTER_CLASS_STREAMS = "REGISTER_CLASS_STREAMS";
export const REGISTER_TERM_ITERATIONS = "REGISTER_TERM_ITERATIONS";
export const REGISTER_WEEK_ITERATIONS = "REGISTER_WEEK_ITERATIONS";
export const REGISTER_ACTUAL_TERMS = "REGISTER_ACTUAL_TERMS";
export const REGISTER_ACTUAL_WEEKS = "REGISTER_ACTUAL_WEEKS";
export const REGISTER_LOT_DESCRIPTION = "REGISTER_LOT_DESCRIPTION";
export const REGISTER_ACTUAL_LOTS = "REGISTER_ACTUAL_LOTS";
export const REGISTER_ACTUAL_CLASSES = "REGISTER_ACTUAL_CLASSES";
export const REGISTER_SYSTEM_USER = "REGISTER_SYSTEM_USER";
export const REGISTER_FEE_COMPONENTS = "REGISTER_FEE_COMPONENTS";
export const REGISTER_FEE_STRUCTURES = "REGISTER_FEE_STRUCTURES";
export const REGISTER_CLASS_FEE_STRUCTURES = "REGISTER_CLASS_FEE_STRUCTURES";
export const REGISTER_CLASS_FEE_COMPONENTS = "REGISTER_CLASS_FEE_COMPONENTS";
export const REGISTER_CLASS_FEE_BREAKDOWN = "REGISTER_CLASS_FEE_BREAKDOWN";
