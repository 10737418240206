export const REGISTER_A_STUDENT_PAGE = "REGISTER_A_STUDENT_PAGE";
export const PAY_FEE = "PAY_FEE";
export const SEND_HOME_FROM_ENTIRE_SCHOOL = "SEND_HOME_FROM_ENTIRE_SCHOOL";
export const SEND_HOME_PER_CLASS = "SEND_HOME_PER_CLASS";
export const SEND_HOME_PER_LOT = "SEND_HOME_PER_LOT";
export const CORRECT_STUDENT_PERSONAL_DETAILS = "CORRECT_STUDENT_PERSONAL_DETAILS";
export const CHANGE_STUDENT_RESIDENCE = "CHANGE_STUDENT_RESIDENCE";
export const ENTIRE_SCHOOL_ANNOUNCEMENT = "ENTIRE_SCHOOL_ANNOUNCEMENT";
export const SPECIFIC_CLASS_ANNOUNCEMENT = "SPECIFIC_CLASS_ANNOUNCEMENT";
export const SPECIFIC_STREAM_ANNOUNCEMENT = "SPECIFIC_STREAM_ANNOUNCEMENT";
export const PAYMENTS_MADE_TODAY = "PAYMENTS_MADE_TODAY";
export const PAYMENTS_MADE_ON_SPECIFIC_DATE = "PAYMENTS_MADE_ON_SPECIFIC_DATE";
export const PAYMENTS_MADE_WITHIN_A_DATE_RANGE = "PAYMENTS_MADE_WITHIN_A_DATE_RANGE";
