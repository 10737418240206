export const STUDENT_REGISTRATION_SUCCESSFUL =
  "STUDENT_REGISTRATION_SUCCESSFUL";
export const STUDENT_REGISTRATION_FAILED = "STUDENT_REGISTRATION_FAILED";
export const ERROR_WHILE_REGISTERING_STUDENT =
  "ERROR_WHILE_REGISTERING_STUDENT";
export const RESET_STUDENT_REGISTRATION = "RESET_STUDENT_REGISTRATION";

export const FEE_PAYMENT_SUCCESSFUL = "FEE_PAYMENT_SUCCESSFUL";
export const FEE_PAYMENT_FAILED = "FEE_PAYMENT_FAILED";
export const ERROR_OCCURRED_WHILE_PAYING_FEE =
  "ERROR_OCCURRED_WHILE_PAYING_FEE";

export const STUDENTS_FETCHED_SUCCESSFULLY = "STUDENTS_FETCHED_SUCCESSFULLY";
export const STUDENTS_FETCHED_EMPTY_RESULT_SET =
  "STUDENTS_FETCHED_EMPTY_RESULT_SET";
export const ERROR_OCCURRED_WHILE_FETCHING_STUDENTS =
  "ERROR_OCCURRED_WHILE_FETCHING_STUDENTS";

export const STUDENT_FEE_STATEMENT_FETCHED_SUCCESSFULLY =
  "STUDENT_FEE_STATEMENT_FETCHED_SUCCESSFULLY";
export const STUDENT_FEE_STATEMENT_FETCHED_EMPTY_RESULT_SET =
  "STUDENT_FEE_STATEMENT_FETCHED_EMPTY_RESULT_SET";
export const ERROR_OCCURRED_WHILE_FETCHING_STUDENT_FEE_STATEMENT =
  "ERROR_OCCURRED_WHILE_FETCHING_STUDENT_FEE_STATEMENT";

export const SCHOOL_FETCH_MINIMUM_FEE_BALANCE_SUCCESSFUL =
  "SCHOOL_FETCH_MINIMUM_FEE_BALANCE_SUCCESSFUL";
export const SCHOOL_FETCH_MINIMUM_FEE_BALANCE_EMPTY_RESULT_SET =
  "SCHOOL_FETCH_MINIMUM_FEE_BALANCE_EMPTY_RESULT_SET";
export const ERROR_OCCURRED_WHILE_FETCHING_SCHOOL_MINIMUM_FEE_BALANCE =
  "ERROR_OCCURRED_WHILE_FETCHING_SCHOOL_MINIMUM_FEE_BALANCE";
export const COMMIT_SEND_STUDENTS_HOME_SEARCH_CRITERIA =
    "COMMIT_SEND_STUDENTS_HOME_SEARCH_CRITERIA";

export const STUDENT_BASIC_DETAILS_UPDATED_SUCCESSFULLY =
  "STUDENT_BASIC_DETAILS_UPDATED_SUCCESSFULLY";
export const STUDENT_BASIC_DETAILS_UPDATE_FAILED =
  "STUDENT_BASIC_DETAILS_UPDATE_FAILED";
export const ERROR_OCCURRED_WHILE_UPDATING_STUDENT_BASIC_DETAILS =
  "ERROR_OCCURRED_WHILE_UPDATING_STUDENT_BASIC_DETAILS";
