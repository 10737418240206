import React, { Component } from "react";

import './TopBar.scss';

class TopBar extends Component {
  render() {
    return (
        <div className="topbar__main-body" >
          <div className="topbar__logo-div">
            Waondo Secondary
          </div>
        </div>
    );
  }
}

export default TopBar;
