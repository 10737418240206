export const initialState = {
  sessionDetails: {},
  isLoginSuccessful: false,
  RoleType: "",
  isSessionActive: false,
  hasWrongLoginCredentials: false,
  accessDenied: false,
  allUsers: [],
  authenticationEventMessage: ''
};
