export const TOGGLE_MODAL_DISPLAY = "TOGGLE_MODAL_DISPLAY";

/* START - ACADEMIC CLASS LEVEL ***************************************************************************************/

export const SETUP_CLASS_LEVEL_FORM = "SETUP_CLASS_LEVEL_FORM";
export const RESET_CURRENT_ACADEMIC_CLASS_LEVEL_CREATED =
  "RESET_CURRENT_ACADEMIC_CLASS_LEVEL_CREATED";

export const BEGIN_CLASS_LEVEL_CREATION = "BEGIN_CLASS_LEVEL_CREATION";
export const CLASS_LEVEL_CREATED_SUCCESSFULLY =
  "CLASS_LEVEL_CREATED_SUCCESSFULLY";
export const CLASS_LEVEL_CREATION_FAILED = "CLASS_LEVEL_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_CLASS_LEVEL =
  "ERROR_OCCURRED_ON_CREATING_CLASS_LEVEL";

export const START_FETCHING_CLASS_LEVELS = "START_FETCHING_CLASS_LEVELS";
export const FETCHING_CLASS_LEVELS_SUCCESSFUL =
  "FETCHING_CLASS_LEVELS_SUCCESSFUL";
export const FETCHING_CLASS_LEVELS_EMPTY_RESULT_SET =
  "FETCHING_CLASS_LEVELS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_CLASS_LEVELS =
  "ERROR_OCCURED_WHILE_FETCHING_CLASS_LEVELS";

/* END - ACADEMIC CLASS LEVEL ***************************************************************************************/

/* START - CLASS STREAMS ***************************************************************************************/

export const BEGIN_CLASS_STREAM_CREATION = "BEGIN_CLASS_STREAM_CREATION";
export const CLASS_STREAM_CREATED_SUCCESSFULLY =
  "CLASS_STREAM_CREATED_SUCCESSFULLY";
export const CLASS_STREAM_CREATION_FAILED = "CLASS_STREAM_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_CLASS_STREAM =
  "ERROR_OCCURRED_ON_CREATING_CLASS_STREAM";

export const START_FETCHING_CLASS_STREAMS = "START_FETCHING_CLASS_STREAMS";
export const FETCHING_CLASS_STREAMS_SUCCESSFUL =
  "FETCHING_CLASS_STREAMS_SUCCESSFUL";
export const FETCHING_CLASS_STREAMS_EMPTY_RESULT_SET =
  "FETCHING_CLASS_STREAMS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_CLASS_STREAMS =
  "ERROR_OCCURED_WHILE_FETCHING_CLASS_STREAMS";

export const RESET_CURRENT_CLASS_STREAM_CREATED =
  "RESET_CURRENT_CLASS_STREAM_CREATED";

export const SETUP_CLASS_STREAM_FORM = "SETUP_CLASS_STREAM_FORM";

/* END - CLASS STREAMS ***************************************************************************************/

/* START - TERM ITERATIONS ***************************************************************************************/

export const START_FETCHING_TERM_ITERATIONS = "START_FETCHING_TERM_ITERATIONS";
export const FETCHING_TERM_ITERATIONS_SUCCESSFUL =
  "FETCHING_TERM_ITERATIONS_SUCCESSFUL";
export const FETCHING_TERM_ITERATIONS_EMPTY_RESULT_SET =
  "FETCHING_TERM_ITERATIONS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_TERM_ITERATIONS =
  "ERROR_OCCURED_WHILE_FETCHING_TERM_ITERATIONS";

export const SETUP_TERM_ITERATIONS_FORM = "SETUP_TERM_ITERATIONS_FORM";

export const RESET_CURRENT_TERM_ITERATION_CREATED =
  "RESET_CURRENT_TERM_ITERATION_CREATED";

export const BEGIN_TERM_ITERATION_CREATION = "BEGIN_TERM_ITERATION_CREATION";
export const TERM_ITERATION_CREATED_SUCCESSFULLY =
  "TERM_ITERATION_CREATED_SUCCESSFULLY";
export const TERM_ITERATION_CREATION_FAILED = "TERM_ITERATION_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_TERM_ITERATION =
  "ERROR_OCCURRED_ON_CREATING_TERM_ITERATION";

/* END - TERM ITERATIONS ***************************************************************************************/

/* START - WEEK ITERATIONS ***************************************************************************************/

export const START_FETCHING_WEEK_ITERATIONS = "START_FETCHING_WEEK_ITERATIONS";
export const FETCHING_WEEK_ITERATIONS_SUCCESSFUL =
  "FETCHING_WEEK_ITERATIONS_SUCCESSFUL";
export const FETCHING_WEEK_ITERATIONS_EMPTY_RESULT_SET =
  "FETCHING_WEEK_ITERATIONS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_WEEK_ITERATIONS =
  "ERROR_OCCURED_WHILE_FETCHING_WEEK_ITERATIONS";

export const RESET_CURRENT_WEEK_ITERATION_CREATED =
  "RESET_CURRENT_WEEK_ITERATION_CREATED";

export const BEGIN_WEEK_ITERATION_CREATION = "BEGIN_WEEK_ITERATION_CREATION";
export const WEEK_ITERATION_CREATED_SUCCESSFULLY =
  "WEEK_ITERATION_CREATED_SUCCESSFULLY";
export const WEEK_ITERATION_CREATION_FAILED = "WEEK_ITERATION_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_WEEK_ITERATION =
  "ERROR_OCCURRED_ON_CREATING_WEEK_ITERATION";

export const SETUP_WEEK_ITERATIONS_FORM = "SETUP_WEEK_ITERATIONS_FORM";

/* END - WEEK ITERATIONS ***************************************************************************************/

/* START - ACTUAL TERMS ***************************************************************************************/

export const START_FETCHING_ACTUAL_TERMS = "START_FETCHING_ACTUAL_TERMS";
export const FETCHING_ACTUAL_TERMS_SUCCESSFUL =
  "FETCHING_ACTUAL_TERMS_SUCCESSFUL";
export const FETCHING_ACTUAL_TERMS_EMPTY_RESULT_SET =
  "FETCHING_ACTUAL_TERMS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_ACTUAL_TERMS =
  "ERROR_OCCURED_WHILE_FETCHING_ACTUAL_TERMS";

export const SETUP_ACTUAL_TERMS_FORM = "SETUP_ACTUAL_TERMS_FORM";

export const RESET_CURRENT_ACTUAL_TERM_CREATED =
  "RESET_CURRENT_ACTUAL_TERM_CREATED";

export const BEGIN_ACTUAL_TERM_CREATION = "BEGIN_ACTUAL_TERM_CREATION";
export const ACTUAL_TERM_CREATED_SUCCESSFULLY =
  "ACTUAL_TERM_CREATED_SUCCESSFULLY";
export const ACTUAL_TERM_CREATION_FAILED = "ACTUAL_TERM_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_ACTUAL_TERM =
  "ERROR_OCCURRED_ON_CREATING_ACTUAL_TERM";

/* END - ACTUAL TERMS ***************************************************************************************/

/* START - ACTUAL WEEKS ***************************************************************************************/

export const START_FETCHING_YEARS_WEEKS = "START_FETCHING_YEARS_WEEKS";
export const FETCHING_YEARS_WEEKS_SUCCESSFUL =
  "FETCHING_YEARS_WEEKS_SUCCESSFUL";
export const FETCHING_YEARS_WEEKS_EMPTY_RESULT_SET =
  "FETCHING_YEARS_WEEKS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_YEARS_WEEKS =
  "ERROR_OCCURED_WHILE_FETCHING_YEARS_WEEKS";

export const SETUP_ACTUAL_WEEKS_FORM = "SETUP_ACTUAL_WEEKS_FORM";

export const RESET_CURRENT_ACTUAL_WEEK_CREATED =
  "RESET_CURRENT_ACTUAL_WEEK_CREATED";

export const BEGIN_ACTUAL_WEEK_CREATION = "BEGIN_ACTUAL_WEEK_CREATION";
export const ACTUAL_WEEK_CREATED_SUCCESSFULLY =
  "ACTUAL_WEEK_CREATED_SUCCESSFULLY";
export const ACTUAL_WEEK_CREATION_FAILED = "ACTUAL_WEEK_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_ACTUAL_WEEK =
  "ERROR_OCCURRED_ON_CREATING_ACTUAL_WEEK";

/* END - ACTUAL WEEKS ***************************************************************************************/

/* START - LOT DESCRIPTIONS ***************************************************************************************/

export const START_FETCHING_ALL_LOT_DESCRIPTIONS =
  "START_FETCHING_ALL_LOT_DESCRIPTIONS";
export const FETCHING_ALL_LOT_DESCRIPTIONS_SUCCESSFUL =
  "FETCHING_ALL_LOT_DESCRIPTIONS_SUCCESSFUL";
export const FETCHING_ALL_LOT_DESCRIPTIONS_EMPTY_RESULT_SET =
  "FETCHING_ALL_LOT_DESCRIPTIONS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_ALL_LOT_DESCRIPTIONS =
  "ERROR_OCCURED_WHILE_FETCHING_ALL_LOT_DESCRIPTIONS";

export const SETUP_LOT_DESCRIPTIONS_FORM = "SETUP_LOT_DESCRIPTIONS_FORM";

export const RESET_CURRENT_LOT_DESCRIPTION_CREATED =
  "RESET_CURRENT_LOT_DESCRIPTION_CREATED";

export const BEGIN_LOT_DESCRIPTION_CREATION = "BEGIN_LOT_DESCRIPTION_CREATION";
export const LOT_DESCRIPTION_CREATED_SUCCESSFULLY =
  "LOT_DESCRIPTION_CREATED_SUCCESSFULLY";
export const LOT_DESCRIPTION_CREATION_FAILED =
  "LOT_DESCRIPTION_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_LOT_DESCRIPTION =
  "ERROR_OCCURRED_ON_CREATING_LOT_DESCRIPTION";

/* END - LOT DESCRIPTIONS ***************************************************************************************/

/* START - ACTUAL LOTS ***************************************************************************************/

export const START_FETCHING_ALL_ACTUAL_LOTS = "START_FETCHING_ALL_ACTUAL_LOTS";
export const FETCHING_ALL_ACTUAL_LOTS_SUCCESSFUL =
  "FETCHING_ALL_ACTUAL_LOTS_SUCCESSFUL";
export const FETCHING_ALL_ACTUAL_LOTS_EMPTY_RESULT_SET =
  "FETCHING_ALL_ACTUAL_LOTS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_ALL_ACTUAL_LOTS =
  "ERROR_OCCURED_WHILE_FETCHING_ALL_ACTUAL_LOTS";

export const SETUP_ACTUAL_LOTS_FORM = "SETUP_ACTUAL_LOTS_FORM";

export const RESET_CURRENT_ACTUAL_LOT_CREATED =
  "RESET_CURRENT_ACTUAL_LOT_CREATED";

export const BEGIN_ACTUAL_LOT_CREATION = "BEGIN_ACTUAL_LOT_CREATION";
export const ACTUAL_LOT_CREATED_SUCCESSFULLY =
  "ACTUAL_LOT_CREATED_SUCCESSFULLY";
export const ACTUAL_LOT_CREATION_FAILED = "ACTUAL_LOT_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_ACTUAL_LOT =
  "ERROR_OCCURRED_ON_CREATING_ACTUAL_LOT";

/* END - ACTUAL LOTS ***************************************************************************************/

/* START - ACTUAL CLASSES ***************************************************************************************/

export const START_FETCHING_ALL_ACTUAL_CLASSES =
  "START_FETCHING_ALL_ACTUAL_CLASSES";
export const FETCHING_ALL_ACTUAL_CLASSES_SUCCESSFUL =
  "FETCHING_ALL_ACTUAL_CLASSES_SUCCESSFUL";
export const FETCHING_ALL_ACTUAL_CLASSES_EMPTY_RESULT_SET =
  "FETCHING_ALL_ACTUAL_CLASSES_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_ALL_ACTUAL_CLASSES =
  "ERROR_OCCURED_WHILE_FETCHING_ALL_ACTUAL_CLASSES";

/* START - LOTS ***************************************************************************************/

export const START_FETCHING_ALL_LOTS_NOT_COMPLETED_SCHOOL =
    "START_FETCHING_ALL_LOTS_NOT_COMPLETED_SCHOOL";
export const FETCHING_ALL_LOTS_NOT_COMPLETED_SCHOOL_SUCCESSFUL =
    "FETCHING_ALL_LOTS_NOT_COMPLETED_SCHOOL_SUCCESSFUL";
export const FETCHING_ALL_LOTS_NOT_COMPLETED_SCHOOL_EMPTY_RESULT_SET =
    "FETCHING_ALL_LOTS_NOT_COMPLETED_SCHOOL_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_FETCHING_ALL_LOTS_NOT_COMPLETED_SCHOOL =
    "ERROR_OCCURED_WHILE_FETCHING_FETCHING_ALL_LOTS_NOT_COMPLETED_SCHOOL";

export const SETUP_ACTUAL_CLASSES_FORM = "SETUP_ACTUAL_CLASSES_FORM";

export const RESET_CURRENT_ACTUAL_CLASS_CREATED =
  "RESET_CURRENT_ACTUAL_CLASS_CREATED";

export const BEGIN_ACTUAL_CLASS_CREATION = "BEGIN_ACTUAL_CLASS_CREATION";
export const ACTUAL_CLASS_CREATED_SUCCESSFULLY =
  "ACTUAL_CLASS_CREATED_SUCCESSFULLY";
export const ACTUAL_CLASS_CREATION_FAILED = "ACTUAL_CLASS_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_ACTUAL_CLASS =
  "ERROR_OCCURRED_ON_CREATING_ACTUAL_CLASS";

/* END - ACTUAL CLASSES ***************************************************************************************/

/* START - SYSTEM USER REGISTRATION ***************************************************************************************/

export const SETUP_SYSTEM_USER_REGISTRATION_FORM =
  "SETUP_SYSTEM_USER_REGISTRATION_FORM";

export const USER_SUCCESSFULLY_REGISTERED = "USER_SUCCESSFULLY_REGISTERED";
export const USER_REGISTRATION_FAILED = "USER_REGISTRATION_FAILED";
export const ERROR_OCCURED_WHILE_REGISTERING_USER =
  "ERROR_OCCURED_WHILE_REGISTERING_USER";

export const USER_SUCCESSFULLY_ASSIGNED_ROLES =
  "USER_SUCCESSFULLY_ASSIGNED_ROLES";
export const USER_ROLE_ASSIGNMENT_FAILED = "USER_ROLE_ASSIGNMENT_FAILED";
export const ERROR_OCCURED_WHILE_ASSIGNING_USER_ROLES =
  "ERROR_OCCURED_WHILE_ASSIGNING_USER_ROLES";

/* END - ACTUAL CLASSES ***************************************************************************************/

/* START - SYSTEM USER REGISTRATION ***************************************************************************************/

export const SETUP_FEE_COMPONENT_REGISTRATION_FORM =
  "SETUP_FEE_COMPONENT_REGISTRATION_FORM";

export const FETCHING_FEE_COMPONENTS_SUCCESSFUL =
  "FETCHING_FEE_COMPONENTS_SUCCESSFUL";
export const FETCHING_FEE_COMPONENTS_EMPTY_RESULT_SET =
  "FETCHING_FEE_COMPONENTS_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_FEE_COMPONENTS =
  "ERROR_OCCURED_WHILE_FETCHING_FEE_COMPONENTS";

export const RESET_CURRENT_FEE_COMPONENT_CREATED =
  "RESET_CURRENT_FEE_COMPONENT_CREATED";

export const FEE_COMPONENT_CREATED_SUCCESSFULLY =
  "FEE_COMPONENT_CREATED_SUCCESSFULLY";
export const FEE_COMPONENT_CREATION_FAILED = "FEE_COMPONENT_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_FEE_COMPONENT =
  "ERROR_OCCURRED_ON_CREATING_FEE_COMPONENT";

/* END - FEE_COMPONENTS_REGISTRATION ***************************************************************************************/

/* START - FEE STRUCTURES ***************************************************************************************/

export const FETCHING_FEE_STRUCTURES_SUCCESSFUL =
  "FETCHING_FEE_STRUCTURES_SUCCESSFUL";
export const FETCHING_FEE_STRUCTURES_EMPTY_RESULT_SET =
  "FETCHING_FEE_STRUCTURES_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_FEE_STRUCTURES =
  "ERROR_OCCURED_WHILE_FETCHING_FEE_STRUCTURES";

export const SETUP_FEE_STRUCTURE_FORM = "SETUP_FEE_STRUCTURE_FORM";

export const FEE_STRUCTURE_CREATED_SUCCESSFULLY =
  "FEE_STRUCTURE_CREATED_SUCCESSFULLY";
export const FEE_STRUCTURE_CREATION_FAILED = "FEE_STRUCTURE_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_FEE_STRUCTURE =
  "ERROR_OCCURRED_ON_CREATING_FEE_STRUCTURE";

export const RESET_CURRENT_FEE_STRUCTURE_CREATED =
  "RESET_CURRENT_FEE_STRUCTURE_CREATED";

/* END - FEE STRUCTURES ***************************************************************************************/

/* START - CLASS FEE STRUCTURES ***************************************************************************************/

export const SETUP_CLASS_FEE_STRUCTURE_FORM = "SETUP_CLASS_FEE_STRUCTURE_FORM";

export const CLASS_FEE_STRUCTURE_CREATED_SUCCESSFULLY =
  "CLASS_FEE_STRUCTURE_CREATED_SUCCESSFULLY";
export const CLASS_FEE_STRUCTURE_CREATION_FAILED =
  "CLASS_FEE_STRUCTURE_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_CLASS_FEE_STRUCTURE =
  "ERROR_OCCURRED_ON_CREATING_CLASS_FEE_STRUCTURE";

/* END - CLASS FEE STRUCTURES ***************************************************************************************/

/* START - CLASS FEE STRUCTURE COMPONENTS ***************************************************************************************/

export const SETUP_CLASS_FEE_STRUCTURE_COMPONENTS_FORM =
  "SETUP_CLASS_FEE_STRUCTURE_COMPONENTS_FORM";

export const FETCHING_CLASS_FEE_STRUCTURES_SUCCESSFUL =
  "FETCHING_CLASS_FEE_STRUCTURES_SUCCESSFUL";
export const FETCHING_CLASS_FEE_STRUCTURES_EMPTY_RESULT_SET =
  "FETCHING_CLASS_FEE_STRUCTURES_EMPTY_RESULT_SET";
export const ERROR_OCCURED_WHILE_FETCHING_CLASS_FEE_STRUCTURES =
  "ERROR_OCCURED_WHILE_FETCHING_CLASS_FEE_STRUCTURES";

export const CLASS_FEE_COMPONENTS_CREATED_SUCCESSFULLY =
  "CLASS_FEE_COMPONENTS_CREATED_SUCCESSFULLY";
export const CLASS_FEE_COMPONENTS_CREATION_FAILED =
  "CLASS_FEE_COMPONENTS_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_CLASS_FEE_COMPONENTS =
  "ERROR_OCCURRED_ON_CREATING_CLASS_FEE_COMPONENTS";

/* END - CLASS FEE STRUCTURE COMPONENTS ***************************************************************************************/

/* START - CLASS FEE STRUCTURE BREAKDOWN ***************************************************************************************/

export const SETUP_CLASS_FEE_STRUCTURE_BREAKDOWN_FORM =
  "SETUP_CLASS_FEE_STRUCTURE_BREAKDOWN_FORM";

export const CLASS_FEE_BREAKDOWN_CREATED_SUCCESSFULLY =
  "CLASS_FEE_BREAKDOWN_CREATED_SUCCESSFULLY";
export const CLASS_FEE_BREAKDOWN_CREATION_FAILED =
  "CLASS_FEE_BREAKDOWN_CREATION_FAILED";
export const ERROR_OCCURRED_ON_CREATING_CLASS_FEE_BREAKDOWN =
  "ERROR_OCCURRED_ON_CREATING_CLASS_FEE_BREAKDOWN";
